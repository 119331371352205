<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <b-card
            no-body
            header-bg-variant="bluenavy"
            header="Iscrizione Partecipanti"
            header-text-variant="white"
            header-tag="h2"
            border-variant="bluenavy"
        >
            <template #header>
                <div style="margin: -2rem -2.25rem; padding: 2rem 2.25rem;">
                    <div class="row">
                        <div class="col-md-10">
                            <h2>Denominazione gara/evento: {{competition.name}}</h2>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-block btn-primary font-weight-bolder" @click.prevent="goBack"><i class="fas fa-arrow-left"></i> Indietro</button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <h6>Totale Partecipanti: {{competition.num_competitors}}</h6>
                        </div>
                        <div class="col-md-4">
                            <h6>Data Svolgimento: {{competition.date}}</h6>
                        </div>
                        <div class="col-md-4">
                            <button v-if="$store.getters.currentUser.usertype !== 'Sodalizio'" type="button" class="btn btn-block btn-danger font-weight-bolder" @click.prevent="exportAllCompetitors"><i class="fas fa-download"></i> Esporta lista completa partecipanti</button>
                        </div>
                    </div>
                </div>
            </template>
            <b-card-text v-if="$store.getters.currentUser.usertype !== 'Sodalizio'">
                <b-alert show variant="secondary" dismissible>
                    <h4 class="alert-heading text-center">INSERIMENTO MASSIVO TESSERATI (ACSI - ALTRI EPS - FEDERAZIONI)</h4>
                    <ol>
                        <li>
                            Scarica il modello xls o ods, compila i campi richiesti (puoi inserire partecipanti anche di altri EPS o Federazioni, saranno validi soltanto se il sodalizio di appartenenza è iscritto al Registro Coni).
                        </li>
                        <li>
                            Carica e processa il file.
                        </li>
                        <li>
                            Visualizza/gestisci o esporta la lista dei partecipanti non ACSI caricata o esporta la lista dei partecipanti con anomalie.
                        </li>
                    </ol>
                    <p>
                        <strong>N.B. Ti ricordiamo che il caricamento di più file non sovrascrive ma aggiunge i partecipanti. I Partecipanti ACSI verranno indicati nella tabella sottostante, raggruppati per sodalizio di appartenenza.</strong>
                    </p>
                </b-alert>
            </b-card-text>

            <b-card-text v-if="$store.getters.currentUser.usertype !== 'Sodalizio'" class="px-3">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <strong>1 SCARICA IL FILE</strong><br>
                        <a href="media/Modello_import_partecipanti.ods" target="_blank" class="btn btn-block btn-success font-weight-bolder"><i class="fas fa-universal-access"></i> Modello ODS</a>
                        <a href="media/Modello_import_partecipanti.xls" target="_blank" class="btn btn-block btn-success font-weight-bolder"><i class="fas fa-file-excel"></i> Modello XLS</a>
                    </div>

                    <div class="col-md-4 text-center">
                        <strong>2 CARICA E PROCESSA</strong><br>
                        <div v-if="false">
                            <div id="epsupload">
                                <FileUpload ref="fileupload" id="filecompetitors" name="filecompetitors" :multiple="false" v-model="filecompetitors" btnclass="primary" btntext="Seleziona file"></FileUpload>

                                <div v-for="(error, index) in fileErrors.filename" class="invalid-feedback d-block">{{error}}</div>
                            </div>
                            <button type="button" class="btn btn-block btn-primary font-weight-bolder" @click.prevent="processCompetitors"><i class="far fa-save"></i> Carica e Processa</button>
                        </div>
                    </div>

                    <div class="col-md-4 text-center">
                        <strong>3 VISUALIZZA E GESTISCI</strong><br>
                        <button v-if="competition.id && competition.type" type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="showAcsiCompetitorsModal"><i class="far fa-eye"></i> Visualizza/gestisci partecipanti non ACSI</button>
                        <button type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="exportCompetitorErrors"><i class="fas fa-download"></i> Esporta partecipanti con anomalie</button>
                        <button type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="exportNotACSI"><i class="fas fa-download"></i> Esporta partecipanti non ACSI</button>
                    </div>

                    <b-modal v-if="competition.type && competition.id" ref="acsiCompetitorsModal" size="lg" hide-footer title="Visualizza/gestisci partecipanti non ACSI">
                        <SmartTable
                            ref="acsicompetitors"
                            :url="'/eps/subscription/acsicompetitors-' + competition.type + '/index'"
                            :is_dialog="true"
                            :base_url="'/eps/subscription/acsicompetitors-' + competition.type"
                            :base_path="'/eps/subscription/acsicompetitors-' + competition.type"
                            :fixed_filters="competition.type === 'event' ? [{ name: 'idevent', filter: competition.id }] : [{ name: 'idcompetition', filter: competition.id }]"
                            custom_class="mx-0 px-0 title-0"
                            v-on:close-modal="updateAcsiCompetitors"
                        >
                            <template v-slot:title>
                                <span></span>
                            </template>
                            <template v-slot:before-nav>
                                <div class="text-center" v-if="false">
                                    <a href="#" @click.prevent="removeSelectedAcsiCompetitorsModal" class="mt-2 btn btn-info font-weight-bolder mr-2">
                                        <i class="fas fa-user-minus"></i> Rimuovi Selezionati
                                    </a>
                                </div>
                            </template>
                            <template v-slot:after-nav>
                                <div class="text-center">
                                    <a href="#" @click.prevent="closeAcsiCompetitorsModal" class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                        <i class="fas fa-window-close"></i> Chiudi
                                    </a>
                                </div>
                            </template>
                        </SmartTable>
                    </b-modal>
                </div>
            </b-card-text>

            <b-card-text v-if="$store.getters.currentUser.usertype !== 'Sodalizio'">
                <b-alert show variant="secondary" dismissible>
                    <h4 class="alert-heading text-center">INSERIMENTO TESSERATI ACSI PER SODALIZIO</h4>
                    <p>
                        Per aggiungere un sodalizio affiliato al tuo comitato utilizza &quot;Selezione Veloce/Menu a tendina&quot;; per aggiungere un sodalizio affiliato ad un altro comitato ACSI inserisci il CF dell'ASD/SSD.
                    </p>
                    <p>
                        Successivamente utilizza il bottone <i class="fas fa-user-plus text-primary"></i> per aggiungere i partecipanti alla gara. Per visualizzare/gestire i partecipanti aggiunti utilizza il bottone <i class="far fa-eye text-primary"></i>.
                    </p>
                    <p>
                        <strong>N.B. Ti ricordiamo che in questa tabella sono indicati anche i tesserati ACSI importati con lo strumento massivo.</strong>
                    </p>
                </b-alert>
            </b-card-text>

            <b-card-text v-if="$store.getters.currentUser.usertype === 'Sodalizio'">
                <b-alert show variant="secondary" dismissible>
                    <h4 class="alert-heading text-center">INSERIMENTO TESSERATI ACSI PER SODALIZIO</h4>
                    <p>
                        Utilizza il bottone <i class="fas fa-user-plus text-primary"></i> per aggiungere i partecipanti alla gara. Per visualizzare/gestire i partecipanti aggiunti utilizza il bottone <i class="far fa-eye text-primary"></i>.
                    </p>
                </b-alert>
            </b-card-text>

            <div v-if="($store.getters.currentUser.usertype !== 'Sodalizio')" class="p-4 bg-secondary rounded">
                <div class="row" v-if="false">
                    <div class="col-md-12 d-flex">
                        <multiselect
                            style="width: 100%;"
                            id="idsportingclub"
                            v-model="idsportingclub"
                            :options="sportingclubs"
                            :multiple="false"
                            :close-on-select="true"
                            :clear-on-select="false"
                            placeholder="Seleziona un sodalizio"
                            label="displayname"
                            track-by="id"
                            :allow-empty="true"
                            selectLabel=""
                            selectedLabel=""
                            deselectLabel=""
                            showLabels=""
                            :class="{ 'is-invalid': typeof errors['idsportingclub'] !== 'undefined' }"
                        >
                            <template v-slot:noOptions>
                                Seleziona un sodalizio
                            </template>
                        </multiselect>
                        <button class="btn btn-primary font-weight-bolder text-nowrap ml-3" type="button" @click.prevent="addSportingclub"><i class="fas fa-plus"></i> Aggiungi</button>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-8" v-if="false">
                        <div class="input-group">
                            <input v-model="sportingclubtaxcode" type="text" class="form-control" placeholder="Codice Fiscale Sodalizio">
                            <div class="input-group-append">
                                <button class="btn btn-primary font-weight-bolder text-nowrap ml-3" type="button" @click.prevent="addSportingclubFromTaxCode"><i class="fas fa-plus"></i> Aggiungi</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <button v-if="competition.id && competition.type" type="button" class="btn btn-block btn-warning font-weight-bolder" @click.prevent="exportCompetitorsACSI"><i class="fas fa-download"></i> Esporta partecipanti ACSI</button>
                    </div>
                </div>
            </div>
        </b-card>

        <div class="table-responsive mt-3 bg-white">
            <table class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered">
                <thead>
                    <tr>
                        <th class="bg-primary"><span class="text-white">Cod. ACSI</span></th>
                        <th class="bg-primary"><span class="text-white">Codice Fiscale</span></th>
                        <th class="bg-primary"><span class="text-white">Denominazione</span></th>
                        <th class="bg-primary"><span class="text-white">Partecipanti</span></th>
                        <th class="bg-primary text-center pr-0"><span class="text-white">Azioni</span></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-if="competition.sportingclubs.length" v-for="(sportingclub, index) in competition.sportingclubs">
                        <td>{{sportingclub.id}}</td>
                        <td>{{sportingclub.taxcode}}</td>
                        <td>{{sportingclub.displayname}}</td>
                        <td class="text-right">{{sportingclub.num_partecipanti}}</td>
                        <td class="text-right">
                            <button
                                v-if="competition.can_delete_sportingclubs && false"
                                type="button"
                                class="btn btn-danger btn-icon btn-circle btn-sm mr-3"
                                @click.prevent="confirmDeleteSportingclub(sportingclub.id)"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>

                            <button
                                v-if="false"
                                type="button"
                                class="btn btn-primary btn-icon btn-circle btn-sm mr-3"
                                @click.prevent="showAddAcsiCompetitorsModal(sportingclub.id)"
                            >
                                <i class="fas fa-user-plus"></i>
                            </button>

                            <button
                                type="button"
                                class="btn btn-primary btn-icon btn-circle btn-sm"
                                @click.prevent="showSportingclubAcsiCompetitorsModal(sportingclub.id)"
                            >
                                <i class="far fa-eye"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <b-modal v-if="competition.type && competition.id" ref="sportingclubAcsiCompetitorsModal" size="lg" hide-footer title="Visualizza/gestisci partecipanti ACSI">
                <SmartTable
                    ref="sportingclubacsicompetitors"
                    :url="'/eps/subscription/sportingclub/acsicompetitors-' + competition.type + '/index'"
                    :is_dialog="true"
                    :base_url="'/eps/subscription/sportingclub/acsicompetitors-' + competition.type"
                    :base_path="'/eps/subscription/sportingclub/acsicompetitors-' + competition.type"
                    :fixed_filters="competition.type === 'event' ? [{ name: 'idevent', filter: competition.id }, { name: 'idsportingclub', filter: idsportingclubmodal }] : [{ name: 'idcompetition', filter: competition.id }, { name: 'idsportingclub', filter: idsportingclubmodal }]"
                    custom_class="mx-0 px-0 title-0"
                    v-on:close-modal="updateSportingclubAcsiCompetitors"
                >
                    <template v-slot:title>
                        <span></span>
                    </template>
                    <template v-slot:before-nav>
                        <div class="text-center" v-if="false">
                            <a href="#" @click.prevent="removeSportingclubSelectedAcsiCompetitorsModal" class="mt-2 btn btn-info font-weight-bolder mr-2">
                                <i class="fas fa-user-minus"></i> Rimuovi Selezionati
                            </a>
                        </div>
                    </template>
                    <template v-slot:after-nav>
                        <div class="text-center">
                            <a href="#" @click.prevent="closeSportingclubAcsiCompetitorsModal" class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                <i class="fas fa-window-close"></i> Chiudi
                            </a>
                        </div>
                    </template>
                </SmartTable>
            </b-modal>

            <b-modal v-if="competition.type && competition.id" ref="addAcsiCompetitorsModal" size="lg" hide-footer title="Aggiungi partecipanti ACSI">
                <SmartTable
                    ref="addacsicompetitors"
                    :url="'/eps/subscription/sportingclub/addacsicompetitors/index'"
                    :is_dialog="true"
                    :base_url="'/eps/subscription/sportingclub/addacsicompetitors'"
                    :base_path="'/eps/subscription/sportingclub/addacsicompetitors'"
                    :fixed_filters="competition.type === 'event' ? [{ name: 'idevent', filter: competition.id }, { name: 'idsportingclub', filter: idsportingclubadd }, { name: 'idannuality', filter: idannuality }] : [{ name: 'idcompetition', filter: competition.id }, { name: 'idsportingclub', filter: idsportingclubadd }, { name: 'idannuality', filter: idannuality }]"
                    custom_class="mx-0 px-0 title-0"
                    v-on:close-modal="updateAddAcsiCompetitors"
                >
                    <template v-slot:title>
                        <span></span>
                    </template>

                    <template v-slot:before-filters>
                        <div class="row">
                            <div class="col-md-12 p-7">
                                <div class="form-group">
                                    <label for="idannuality" class="font-weight-bolder">Annualità:</label>
                                    <select class="form-control" id="idannuality" v-model="idannuality">
                                        <option v-for="annuality in annualities" :value="annuality.id">{{annuality.solarsannualitydisplay}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td class="text-nowrap pr-0 text-right">
                            <a href="#" @click.prevent="addAcsiCompetitor(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Aggiungi" alt="Aggiungi">
                                <i class="fas fa-user-plus"></i>
                            </a>
                        </td>
                    </template>
                    <template v-slot:before-nav>
                        <div class="text-center">
                            <a href="#" @click.prevent="addSelectedAcsiCompetitorsModal" class="mt-2 btn btn-info font-weight-bolder mr-2">
                                <i class="fas fa-user-plus"></i> Aggiungi Selezionati
                            </a>
                        </div>
                    </template>
                    <template v-slot:after-nav>
                        <div class="text-center">
                            <a href="#" @click.prevent="closeAddAcsiCompetitorsModal" class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                <i class="fas fa-window-close"></i> Chiudi
                            </a>
                        </div>
                    </template>
                </SmartTable>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import ApiService from "@/core/services/api.service";
 import JwtService from "@/core/services/jwt.service";
 import SmartTable from "@/view/components/SmartTable.vue";
 import FileUpload from "@/view/components/FileUpload.vue";
 import Swal from 'sweetalert2';

 export default {
     data() {
         return {
             loading: false,

             isLoading: false,
             sportingclubs: [],
             idsportingclub: '',
             sportingclubtaxcode: '',
             errors: [],

             idsportingclubmodal: null,
             idsportingclubadd: null,

             url: '',

             filecompetitors: '',
             fileErrors: [],

             competitors: [],
             competitorsErrors: [],

             competition: {
                 id: null,
                 type: null,
                 name: '',
                 date: '',
                 num_competitors: 0,
                 can_delete_sportingclubs: false,
                 sportingclubs: [],
             },

             idannuality: '',
             annualities: [],
         }
     },

     components: {
         SmartTable,
         FileUpload,
     },

     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Portale EPS", route: "/eps-portal" },
             { title: "Iscrizione Partecipanti", route: "" },
         ]);
     },

     created() {
         this.updateCompetition();
         this.getSportingclubs();

         let url = '/eps/subscription/sportingclub/addacsicompetitors/load-options?type=annualities';

         ApiService.query(url)
                   .then((response) => {
                       this.annualities = response.data;

                       if (this.annualities.length) {
                           if (!this.idannuality)
                           {
                               this.idannuality = this.annualities[0].id;
                           }
                       }
                   })
                   .catch((error) => {
                       console.log(error);
                   })
     },

     watch: {
         $route: {
             handler: function () {
                 this.updateCompetition();
             },

             deep: true,
         },

         idannuality(newValue) {
             let vm = this;
             setTimeout(function() {
                 if (typeof vm.$refs.addacsicompetitors !== 'undefined') vm.$refs.addacsicompetitors.refresh();
             }, 200);
         },
     },

     methods: {
         getSportingclubs() {
             let url = '/eps/subscription/get-sportingclubs';

             ApiService.query(url)
                       .then((response) => {
                           this.sportingclubs = response.data;
                       })
                       .catch((error) => {
                           console.log(error);
                       })
         },

         closeModal() {
         },

         goBack() {
             window.history.go(-1);
         },

         getApiUrl() {
             return ApiService.getApiUrl();
         },

         appendToken(url) {
             if (url) {
                 let parts = url.split('?');
                 if (parts.length >= 2) {
                     return url += '&token=' + JwtService.getToken();
                 }
                 else
                 {
                     return url += '?token=' + JwtService.getToken();
                 }
             }

             return url;
         },

         updateCompetition() {
             this.competition = {
                 id: null,
                 type: null,
                 name: '',
                 date: '',
                 num_competitors: 0,
                 can_delete_sportingclubs: false,
                 sportingclubs: [],
             },

             this.competition.id = this.$route.params.id;
             this.competition.type = this.$route.params.type;

             this.url = '/eps/subscription/load-options?type=competitioninfo&idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             let url = this.url;

             ApiService.query(url)
                       .then((response) => {
                           let competition = response.data;

                           this.competition.name = competition.name;
                           this.competition.date = competition.date;
                           this.competition.num_competitors = competition.num_competitors;
                           this.competition.sportingclubs = competition.sportingclubs;
                           this.competition.can_delete_sportingclubs = competition.can_delete_sportingclubs;
                       })
                       .catch((error) => {
                           console.log(error);
                       })
         },

         exportAllCompetitors() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/eps/subscription/export-all-competitors?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         exportNotACSI() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/eps/subscription/export-not-acsi?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         exportCompetitorErrors() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/eps/subscription/export-competitor-errors?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         exportCompetitorsACSI() {
             let url = process.env.VUE_APP_API_ENDPOINT + '/eps/subscription/export-competitors-acsi?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

             url = this.appendToken(url);

             window.open(url);
         },

         showAcsiCompetitorsModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['acsiCompetitorsModal'].show();
             }, 200);
         },

         closeAcsiCompetitorsModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['acsiCompetitorsModal'].hide();
             }, 200);
         },

         closeAddAcsiCompetitorsModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['addAcsiCompetitorsModal'].hide();
             }, 200);
         },

         updateAcsiCompetitors() {
             this.$refs.acsicompetitors.refresh();
             this.updateCompetition();
         },

         updateAddAcsiCompetitors() {
             this.$refs.addacsicompetitors.refresh();
             this.updateCompetition();
         },

         showSportingclubAcsiCompetitorsModal(idsportingclub) {
             this.idsportingclubmodal = idsportingclub;

             let vm = this;
             setTimeout(function() {
                 vm.$refs['sportingclubAcsiCompetitorsModal'].show();
             }, 200);
         },

         showAddAcsiCompetitorsModal(idsportingclub) {
             this.idsportingclubadd = idsportingclub;

             let vm = this;
             setTimeout(function() {
                 vm.$refs['addAcsiCompetitorsModal'].show();
             }, 200);
         },

         closeSportingclubAcsiCompetitorsModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['sportingclubAcsiCompetitorsModal'].hide();
             }, 200);
         },

         updateSportingclubAcsiCompetitors() {
             this.$refs.sportingclubacsicompetitors.refresh();
             this.updateCompetition();
         },

         processCompetitors() {
             this.loading = true;

             let url = '/eps/subscription/process-competitors?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;
             let data = { filename: this.filecompetitors };

             this.fileErrors = {};
             this.competitorsErrors = {};

             ApiService.post(url, data)
                       .then((response) => {
                           this.$bvToast.toast('Partecipanti importati correttamente.', {
                               title: 'Partecipanti importati correttamente',
                               variant: 'success',
                               solid: true
                           });

                           this.competitors = response.data;

                           this.updateCompetition();

                           this.$refs.fileupload.deleteFile(this.$refs.fileupload.files[0]);

                           this.loading = false;
                       })
                       .catch(({ response }) => {
                           if (response.status === 422) {
                               this.$bvToast.toast('Si è verificato un errore nell\'elaborazione del file: controllare i dati inseriti e riprovare.', {
                                   title: 'Errore nell\'elaborazione del file',
                                   variant: 'danger',
                                   solid: true
                               });

                               if (typeof response.data.fileerrors !== "undefined") {
                                   this.fileErrors = response.data.fileerrors;
                               }

                               if (typeof response.data.errors !== "undefined") {
                                   this.competitorsErrors = response.data.errors;
                               }
                           }
                           else {
                               console.log(response);
                           }

                           this.loading = false;
                       });
         },

         addSportingclub() {
             if (this.idsportingclub) {
                 this.loading = true;

                 let url = '/eps/subscription/add-sportingclub?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

                 let data = { sportingclub: this.idsportingclub };

                 ApiService.post(url, data)
                           .then((response) => {
                               if (response.data.status === 'OK') {
                                   this.idsportingclub = '';

                                   this.$bvToast.toast('Sodalizio aggiunto correttamente', {
                                       title: 'Sodalizio aggiunto correttamente',
                                       variant: 'success',
                                       solid: true
                                   });

                                   this.updateCompetition();

                                   this.loading = false;
                               }

                               if (response.data.status === 'KO') {
                                   this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta del sodalizio', {
                                       title: 'Errore nell\'aggiunta del sodalizio',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           })
                           .catch((error) => {
                               console.log(error);

                               this.loading = false;
                           });
             }
             else {
                 Swal.fire({
                     title: 'Nessun sodalizio selezionato',
                     text: "Nessun sodalizio selezionato",
                     icon: 'warning',
                     showConfirmButton: true,
                     confirmButtonText: 'Ok',
                 });

                 this.loading = false;
             }
         },

         addSportingclubFromTaxCode() {
             if (this.sportingclubtaxcode) {
                 this.loading = true;

                 let url = '/eps/subscription/add-sportingclub-taxcode?idcompetition=' + this.competition.id + '&competitiontype=' + this.competition.type;

                 let data = { taxcode: this.sportingclubtaxcode };

                 ApiService.post(url, data)
                           .then((response) => {
                               if (response.data.status === 'OK') {
                                   this.sportingclubtaxcode = '';

                                   this.$bvToast.toast('Sodalizio aggiunto correttamente', {
                                       title: 'Sodalizio aggiunto correttamente',
                                       variant: 'success',
                                       solid: true
                                   });

                                   this.updateCompetition();

                                   this.loading = false;
                               }

                               if (response.data.status === 'KO') {
                                   this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta del sodalizio', {
                                       title: 'Errore nell\'aggiunta del sodalizio',
                                       variant: 'danger',
                                       solid: true
                                   });

                                   this.loading = false;
                               }
                           })
                           .catch((error) => {
                               console.log(error);

                               this.loading = false;
                           });
             }
             else {
                 Swal.fire({
                     title: 'Nessun codice fiscale sodalizio inserito',
                     text: "Nessun codice fiscale sodalizio inserito",
                     icon: 'warning',
                     showConfirmButton: true,
                     confirmButtonText: 'Ok',
                 });

                 this.loading = false;
             }
         },

         removeSelectedAcsiCompetitorsModal() {
             Swal.fire({
                 title: 'Conferma rimozione selezionati',
                 text: "Sei sicuro di voler rimuovere i partecipanti selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitors = [];
                     for (let i in this.$refs.acsicompetitors.items) {
                         if (this.$refs.acsicompetitors.items[i].checked) competitors.push({ idcompetitor: this.$refs.acsicompetitors.items[i].id } );
                     }

                     if (competitors.length) {
                         let url = '/eps/subscription/remove-acsi-competitors';
                         let data = {
                             competitors: competitors,
                             idcompetition: this.competition.id,
                             competitiontype: this.competition.type,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti rimossi correttamente', {
                                               title: 'Partecipanti rimossi correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.acsicompetitors.refresh();
                                           this.updateCompetition();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nella rimozione dei partecipanti.', {
                                               title: 'Errore nella rimozione dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun partecipante selezionato',
                             text: "Per effettuare la rimozione selezionare almeno un partecipante",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         addSelectedAcsiCompetitorsModal() {
             Swal.fire({
                 title: 'Conferma aggiunta selezionati',
                 text: "Sei sicuro di voler aggiungere i partecipanti selezionati?",
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitors = [];
                     for (let i in this.$refs.addacsicompetitors.items) {
                         if (this.$refs.addacsicompetitors.items[i].checked) competitors.push({ idcompetitor: this.$refs.addacsicompetitors.items[i].id } );
                     }

                     if (competitors.length) {
                         let url = '/eps/subscription/add-acsi-competitors';
                         let data = {
                             competitors: competitors,
                             idcompetition: this.competition.id,
                             competitiontype: this.competition.type,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti aggiunti correttamente', {
                                               title: 'Partecipanti aggiunti correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.addacsicompetitors.refresh();
                                           this.updateCompetition();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta dei partecipanti.', {
                                               title: 'Errore nell\'aggiunta dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun partecipante selezionato',
                             text: "Per effettuare l\'aggiunta selezionare almeno un partecipante",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         addAcsiCompetitor(idcompetitor) {
             Swal.fire({
                 title: 'Conferma aggiunta partecipante',
                 text: "Sei sicuro di voler aggiungere il partecipante selezionato?",
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitors = [];
                     competitors.push({ idcompetitor: idcompetitor } );

                     if (competitors.length) {
                         let url = '/eps/subscription/add-acsi-competitors';
                         let data = {
                             competitors: competitors,
                             idcompetition: this.competition.id,
                             competitiontype: this.competition.type,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipante aggiunto correttamente', {
                                               title: 'Partecipante aggiunto correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.addacsicompetitors.refresh();
                                           this.updateCompetition();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'aggiunta del partecipante.', {
                                               title: 'Errore nell\'aggiunta del partecipante',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun partecipante selezionato',
                             text: "Per effettuare l\'aggiunta selezionare almeno un partecipante",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         removeSportingclubSelectedAcsiCompetitorsModal() {
             Swal.fire({
                 title: 'Conferma rimozione selezionati',
                 text: "Sei sicuro di voler rimuovere i partecipanti selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitors = [];
                     for (let i in this.$refs.sportingclubacsicompetitors.items) {
                         if (this.$refs.sportingclubacsicompetitors.items[i].checked) competitors.push({ idcompetitor: this.$refs.sportingclubacsicompetitors.items[i].id } );
                     }

                     if (competitors.length) {
                         let url = '/eps/subscription/sportingclub/remove-acsi-competitors';
                         let data = {
                             idsportingclub: this.idsportingclubmodal,
                             competitors: competitors,
                             idcompetition: this.competition.id,
                             competitiontype: this.competition.type,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti rimossi correttamente', {
                                               title: 'Partecipanti rimossi correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.sportingclubacsicompetitors.refresh();
                                           this.updateCompetition();
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nella rimozione dei partecipanti.', {
                                               title: 'Errore nella rimozione dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun partecipante selezionato',
                             text: "Per effettuare la rimozione selezionare almeno un partecipante",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         confirmDeleteSportingclub(idsportingclub) {
             Swal.fire({
                 title: 'Conferma eliminazione Sodalizio',
                 text: "Sei sicuro di voler eliminare il sodalizio selezionato e tutti i suoi partecipanti?",
                 icon: 'error',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/eps/subscription/sportingclub/remove-sportingclub';
                     let data = {
                         idsportingclub: idsportingclub,
                         idcompetition: this.competition.id,
                         competitiontype: this.competition.type,
                     };

                     ApiService.post(url, data)
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Sodalizio eliminato correttamente', {
                                           title: 'Sodalizio eliminato correttamente',
                                           variant: 'success',
                                           solid: true
                                       });

                                       this.updateCompetition();
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si sono verificati degli errori nell\'eliminazione del sodalizio', {
                                           title: 'Errore nell\'eliminazione del sodalizio',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }
                               })
                               .catch(({response}) => {
                                   console.log(response);
                               });

                     this.loading = false;
                 }
             });
         },
     },
 };
</script>

<style>
 ul.nav-tabs .nav-item a.nav-link {
     height: 100%;
 }

 .title-0 {
     margin-bottom: 0px !important;
     padding-bottom: 0px !important;
     min-height: 0px !important;
 }

 .card-header.title-0 {
     height: 0px !important;
     overflow: hidden !important;
     padding-top: 0px !important;
 }

 .modal-body {
     padding-top: 0px !important;
 }

 #epsupload .upload-trigger {
     width: 100%;
     cursor: pointer;
 }

 #epsupload .upload-zone {
     width: 100%;
     cursor: pointer;
 }
</style>
